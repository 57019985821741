import project1 from "../Home/Assets/ShiYe_Apartment.png"
import project2 from "../Home/Assets/beach02.jpg"
import Comfort_Home from "../Home//Assets/Comfort_Home.jpg"
import Comfort_Home02 from "../Home//Assets/Comfort_Home02.jpg"
import Comfort_Home03 from "../Home//Assets/Comfort_Home03.jpg"
import Comfort_Home04 from "../Home//Assets/Comfort_Home04.jpg"
import Comfort_Home05 from "../Home//Assets/Comfort_Home05.jpg"
import Comfort_Home06 from "../Home//Assets/Comfort_Home06.jpg"
import Comfort_Home07 from "../Home//Assets/Comfort_Home07.jpg"

import Comfort_House01 from "../Home//Assets/Comfort_House01.jpg"
import Comfort_House02 from "../Home//Assets/Comfort_House02.jpg"
import Comfort_House03 from "../Home//Assets/Comfort_House03.jpg"
import Comfort_House04 from "../Home//Assets/Comfort_House04.jpg"
import Comfort_House05 from "../Home//Assets/Comfort_House05.jpg"
import Comfort_House06 from "../Home//Assets/Comfort_House06.jpg"
import Comfort_House07 from "../Home//Assets/Comfort_House07.jpg"
import Comfort_House08 from "../Home//Assets/Comfort_House08.jpg"

export const Content50DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  page: { className: "home-page content5" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      { name: "title", children: "Hotel Projects", className: "title-h1" },
      {
        name: "content",
        className: "title-content",
        children: "We have strong expertises in VR industry",
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/Sunset-Lodge/",
          img: {
            children: Comfort_Home,
            className: "kiz1kwq2swo-editor_css",
          },
          content: { children: "Sunset Lodge" },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/LakeFront/",
          img: {
            children: project2,
            className: "kiz1kgozk5u-editor_css",
          },
          content: { children: "LakeFront Apartments" },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home03,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
      {
        name: "block3",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/RiverApartment/",
          img: {
            children: Comfort_Home02,
            className: "kiz15f2s426-editor_css",
          },
          content: { children: "James Hotel" },
        },
      },
      {
        name: "block4",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home04,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "HaiBian Apartments" },
        },
      },
      {
        name: "block5",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/RiverApartment/",
          img: {
            children: Comfort_Home05,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "WenXin Apartments" },
        },
      },
      {
        name: "block6",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home06,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "Lang Apartments" },
        },
      },
      {
        name: "block7",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/RiverApartment/",
          img: {
            children: Comfort_Home07,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "JingDian Apartments" },
        },
      },
    ],
  },
}

export const Content60DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  page: { className: "home-page content5 kj35cowxop-editor_css" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      { name: "title", children: "Shop Projects", className: "title-h1" },
      {
        name: "content",
        className: "title-content",
        children:
          "We have strong expertises in hotel and service apartment industry",
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "https://720yun.com/t/82vkOhrlzqh?scene_id=57843970",
          img: {
            children: Comfort_Home,
            className: "kiz1kwq2swo-editor_css",
          },
          content: { children: "Comfort Home Hotel" },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "https://720yun.com/t/ebvksm2lr19?scene_id=48945073",
          img: {
            children: project1,
            className: "kiz1kgozk5u-editor_css",
          },
          content: { children: "ShiYe Apartments" },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home03,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
      {
        name: "block3",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "https://720yun.com/t/82vkOhrlzqh?scene_id=57843970",
          img: {
            children: Comfort_Home02,
            className: "kiz15f2s426-editor_css",
          },
          content: { children: "James Hotel" },
        },
      },
      {
        name: "block4",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home03,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
      {
        name: "block5",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home03,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
      {
        name: "block6",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home03,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
      {
        name: "block7",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/portfolio/MeiYuan/",
          img: {
            children: Comfort_Home03,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
    ],
  },
}

export const Content70DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  page: { className: "home-page content5 kj35cowxop-editor_css" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      { name: "title", children: "House Projects", className: "title-h1" },
      {
        name: "content",
        className: "title-content",
        children:
          "We have strong expertises in hotel and service apartment industry",
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/",
          img: {
            children: Comfort_House01,
            className: "kiz1kwq2swo-editor_css",
          },
          content: { children: "Comfort Home Hotel" },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/",
          img: {
            children: Comfort_House08,
            className: "kiz1kgozk5u-editor_css",
          },
          content: { children: "ShiYe Apartments" },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/",
          img: {
            children: Comfort_House02,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
      {
        name: "block3",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/",
          img: {
            children: Comfort_House03,
            className: "kiz15f2s426-editor_css",
          },
          content: { children: "James Hotel" },
        },
      },
      {
        name: "block4",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/",
          img: {
            children: Comfort_House04,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
      {
        name: "block5",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/",
          img: {
            children: Comfort_House05,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "New" },
        },
      },
      {
        name: "block6",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/",
          img: {
            children: Comfort_House06,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
      {
        name: "block7",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          href: "/",
          img: {
            children: Comfort_House07,
            className: "kiz1h7qtvqk-editor_css",
          },
          content: { children: "River Apartments" },
        },
      },
    ],
  },
}

export const Content40DataSource = {
  wrapper: { className: "home-page-wrapper content4-wrapper" },
  page: { className: "home-page content4 kj35cowxop-editor_css" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: "360° video example",
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content content4-title-content",
        children: "vitural tour video can be uploaded to youtube and facebook",
      },
    ],
  },
  video: {
    className: "content4-video",
    children: {
      video: "https://os.alipayobjects.com/rmsportal/EejaUGsyExkXyXr.mp4",
      image: "https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg",
    },
  },
}
