/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react"
import { enquireScreen } from "enquire-js"

import Content5 from "./Content5"
// import Content4 from "./Content4";

import {
  Content50DataSource,
  // Content60DataSource,
  Content70DataSource,
  // Content40DataSource,
} from "./data.source"
import "./less/antMotionStyle.less"

let isMobile
enquireScreen(b => {
  isMobile = b
})

const { location = {} } = typeof window !== "undefined" ? window : {}

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        })
      }, 500)
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Content5
        id="Content5_0"
        key="Content5_0"
        dataSource={Content50DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Content5
      //   id="Content5_0"
      //   key="Content5_0"
      //   dataSource={Content60DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Content5
        id="Content5_0"
        key="Content5_0"
        dataSource={Content70DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Content4
      //   id="Content4_0"
      //   key="Content4_0"
      //   dataSource={Content40DataSource}
      //   isMobile={this.state.isMobile}
      // />,
    ]
    return (
      <div
        className="templates-wrapper"
        ref={d => {
          this.dom = d
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    )
  }
}
